<template>
  <div class="flex justify-center items-center">
    <div class="text-center">
      <h1 class="text-2xl text-white font-bold">Page Not Found</h1>
      <router-link to="/" class="text-white text-sm px-6 bg-red-400 px-1 py-2 inline-block rounded-full mt-2">Back To
        Home
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotFound"
}
</script>

<style scoped>

</style>